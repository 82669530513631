import React from "react"
import Layout from '../../src/components/Layout'
import {
  Container,
  Section,
  CloseButton,
  Title,
} from '../../src/components/style/Global'
import { navigate } from "gatsby"
import Close from '../assets/close.svg'

const Privacy = () => {
  return ( 

    <Layout>
      
    <CloseButton
      onClick={() =>
        navigate(`/`, {
          state: { noScroll: true },
        })
      }
    >
    <Close />
    </CloseButton>
    <Section>
      <Container>
        <Title center>Privatnost i sigurnost</Title>
       
        <p>Kako bi se osigurao ispravan rad ovih web-stranica, ponekad na va&scaron;e uređaje pohranjujemo male podatkovne datoteke poznate pod nazivom kolačići. Postavke o kolačićima možete urediti i/ili ažurirati ili obrisati prilikom svakog posjeta na&scaron;oj stranici.</p>
<p>Vi&scaron;e pročitajte u Pravilima privatnosti i Pravilima o kori&scaron;tenju kolačića pod Uvjeti kori&scaron;tenja.</p>
<p>&nbsp;</p>
<p><strong>Uvjeti kori&scaron;tenja</strong></p>
<p><strong>PRAVILA O KORI&Scaron;TENJU KOLAČIĆA</strong></p>
<p><strong>&Scaron;to su kolačići?</strong></p>
<p>Kolačić je mala tekstna datoteka koja se pohranjuje na računalo ili mobilni uređaj pri posjetu određenom web-mjestu. S pomoću kolačića web-mjesto pamti va&scaron;e radnje i željene postavke (poput prijave, jezika, veličine fonta i drugih željenih postavki koje se odnose na prikaz) tijekom duljeg razdoblja, pa ih nije potrebno ponovno unositi pri svakom povratku na web-mjesto odnosno pri pregledavanju njegovih različitih stranica.</p>
<p>&nbsp;</p>
<p><strong>Na koji se način koristimo kolačićima?</strong></p>
<p>OPG Miodrag De&scaron;a upotrebljava kolačiće i slične tehnologije za nekoliko svrha, ovisno o kontekstu ili proizvodu, uključujući:</p>
<p>1 privremene korisničke kolačiće unosa (session-id) i/ili trajne kolačiće ograničene na nekoliko sati u nekim slučajevima &ndash; koristimo ih za pohranjivanje va&scaron;ih preferencija i postavki na va&scaron;em uređaju te za pobolj&scaron;avanje va&scaron;eg iskustva. Na primjer, spremanje va&scaron;ih osobnih preferenci u kolačiće &scaron;to vam omogućuje da ne morate stalno iznova postavljati preference. Također, ako odustanete od oglasa utemeljenih na interesima, mi pohranjujemo va&scaron;e odabire za odustajanje u kolačić na va&scaron;em uređaju;</p>
<p>2 kolačiće za provjeru autentičnosti, koji se koriste za autentifikacijske usluge, tijekom trajanja pregleda stranice - koristimo ih da bismo provjerili va&scaron;u autentičnost. Taj vam kolačić omogućuje kretanje od stranice do stranice bez potrebe za ponovnim prijavljivanjem na svakoj stranici. Možete spremiti i podatke za prijavu tako da se ne morate prijaviti svaki put kada se vratite na web-mjesto;</p>
<p>3 korisničke sigurnosne kolačiće, koji se koriste za otkrivanje zloupotreba autentičnosti, ograničenog trajanja - koristimo ih za obradu informacija koje nam omogućuju sigurnost te za utvrđivanje prevare i zloupotrebe;</p>
<p>4 kolačiće multimedijskih sadržaja, kao &scaron;to su kolačići za flash player, za vrijeme trajanja pregleda stranice;</p>
<p>5 kolačiće omogućavanja učitavanja, tijekom trajanja pregleda stranice - koristimo ih da bismo zapamtili informacije koje nam dajete, te podatke pohranjujemo u kolačić kako bismo mogli zapamtiti te podatke;</p>
<p>6 trajne kolačiće za prilagodbu korisničkog sučelja za vrijeme trajanja pregleda stranice (ili malo duže) - kolačiće koristimo da bi vam omogućili davanje povratnih informacija na web-mjestu;</p>
<p>7 kolačiće trećih strana za dijeljenje dodataka dru&scaron;tvenih mreža za prijavljene članove dru&scaron;tvene mreže - neka na&scaron;a web-mjesta uključuju kolačiće dru&scaron;tvenih medija, uključujući one koji korisnicima koji su prijavljeni na dru&scaron;tvenu mrežu omogućuju dijeljenje sadržaja preko te mreže. To se događa na web stranicama koje Vam omogućavaju prijavu i registraciju preko računa dru&scaron;tvenih mreža te ukoliko dijelite sadržaj web stranice na dru&scaron;tvenim mrežama (npr. putem &bdquo;Like&ldquo; gumba). Konkretni utjecaj na Va&scaron;u privatnost će se razlikovati od dru&scaron;tvene mreže do dru&scaron;tvene mreže te ovisi o postavkama privatnosti koje ste izabrali na tim mrežama. Kako upravljati ovim kolačićima možete saznati na njihovim web stranicama.</p>
<p>Uz navedene kolačiće koristimo i:</p>
<ol>
<li>kolačiće dodataka dru&scaron;tvenih mreža namijenjeni praćenju;</li>
<li>promidžbene kolačiće trećih strana- upotrebljavamo kolačiće za prikupljanje podataka o va&scaron;oj aktivnosti na mreži i prepoznavanju va&scaron;ih interesa tako da vam možemo prikazati oglase koji bi vas mogli najvi&scaron;e zanimati. Primanje oglasa utemeljenih na interesima koje vam &scaron;aljemo možete i otkazati, kao &scaron;to je opisano u na&scaron;im Pravilima privatnosti. Također, upotrebljavamo kolačiće da bi zabilježili, primjerice, koliko je posjetitelja kliknulo na oglas i koje ste oglase vidjeli tako da vam se vi&scaron;e puta ne prikazuju isti.</li>
<li>analitičke kolačiće- koristimo kolačiće prve i treće strane te druge identifikatore za prikupljanje podataka o upotrebi i performansama, na primjer: možemo ih koristiti za brojanje jedinstvenih posjetitelja na nekom web-mjestu ili servisu te za razvoj drugih statistika o radu na&scaron;ih web-mjesta i proizvoda ili da bi saznali kakve su performanse na&scaron;ih proizvoda da bi ih mogli pobolj&scaron;ati (na primjer, kolačiće koristimo da bismo prikupili podatke koji pomažu u raspodjeli opterećenja).To omogućuje da na&scaron;a web-mjesta mogu funkcionirati.</li>
</ol>
<p>&nbsp;</p>
<p>Važno je napomenuti i da neki videozapisi ugrađeni na na&scaron;e stranice upotrebljavaju kolačić za anonimno prikupljanje statistika o tome kako ste do&scaron;li i videozapise koje ste posjetili.</p>
<p>&nbsp;</p>
<p>Omogućavanje ovih kolačića nije strogo potrebno za web stranicu, ali će vam pružiti bolji doživljaj pregledavanja.</p>
<p>&nbsp;</p>
<p>Možete izbrisati ili blokirati te kolačiće, ali ako to činite, neke značajke ove web stranice možda neće funkcionirati.</p>
<p>&nbsp;</p>
<p>Informacije vezane uz kolačiće se ne koriste da bi vas osobno identificirali, a podaci o uzorcima potpuno su pod na&scaron;om kontrolom.</p>
<p>&nbsp;</p>
<p>Ovi kolačići se ne koriste za bilo koju svrhu osim onih opisanih ovdje.</p>
<p>&nbsp;</p>
<p><strong>Koristimo li i druge kolačiće?</strong></p>
<p>Neke od na&scaron;ih stranica ili podstranica mogu koristiti dodatne i/ili različite kolačiće od onih koje smo prethodno naveli i opisali.</p>
<p>&nbsp;</p>
<p>Na primjer:</p>
<p>- tvrtke koje unajmljujemo za pružanje usluga u na&scaron;e ime, kao &scaron;to je analitika web-mjesta, postavljaju kolačiće kada posjetite na&scaron;a web-mjesta,</p>
<p>- tvrtke koje isporučuju sadržaj, kao &scaron;to su videozapisi ili vijesti ili oglasi na na&scaron;im web-mjestima postavljaju vlastite kolačiće. spomenute tvrtke podatke koje obrađuju koriste u skladu sa svojim Pravilnicima o za&scaron;titi podataka &scaron;to tim tvrtkama može omogućiti prikupljanje i kombiniranje informacija o va&scaron;im aktivnostima s vi&scaron;e web-mjesta, aplikacija ili mrežnih servisa.</p>
<p>Detalji o njima bit će vam omogućeni u trenutku kada se isti pojave te ćemo u tom trenutku od Vas tražiti privolu, ukoliko istu niste prije dali, kako bismo pohranili i te kolačiće.</p>
<p>&nbsp;</p>
<p><strong>Kako možete kontrolirati kolačiće?</strong></p>
<p>Kolačiće možete kontrolirati i/ili izbrisati prema želji.</p>
<p>Možete izbrisati sve kolačiće koji su već pohranjeni na računalu, a postavke većine preglednika omogućuju blokiranje pohrane kolačića. Ako blokirate kolačiće, možda ćete pri svakom posjetu web-mjestu morati ručno prilagođivati neke željene postavke, a određene usluge i funkcije možda neće biti dostupne.</p>
<p>&nbsp;</p>
<p><strong>Kako obrisati kolačiće?</strong></p>
<p>Većina kolačića lako se može obrisati, no brisanje ili blokiranje svih kolačića moglo imati negativan utjecaj na kori&scaron;tenje stranice.</p>
<p>Odlučite li se ipak obrisati ili onemogućiti kolačiće na svojem računalu potrebno je ažurirati postavke svojeg internetskog preglednika (informacije o načinu brisanja i onemogućavanja kolačića potražite na svojem pregledniku odabirom izbornika za pomoć).</p>
<p>&nbsp;</p>
<p><strong>Dodatne informacija oko isključivanja kolačića</strong></p>
<p>Trenutno postoji nekoliko web-stranica za isključivanje pohranjivanja kolačića za različite servise.</p>
<p>Vi&scaron;e se možete informirati na sljedećim web adresama:</p>
<p>www.allaboutcookies.org</p>
<p>www.youronlinechoices.com/hr</p>
<p>www.aboutads.info/choices</p>
<p>https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</p>
<p>http://www.addthis.com/privacy/privacy-policy</p>
<p>&nbsp;</p>
<p>Uz navedeno od velike pomoći mogu vam biti i sljedeće web stranice:</p>
<ul>
<li>European Interactive Digital Advertising Alliance (EU)</li>
<li>Internet Advertising Bureau (US)</li>
<li>Internet Advertising Bureau (EU)</li>
<li>Google Chrome</li>
<li>Internet Explorer</li>
<li>Mozilla Firefox</li>
<li>Safari (Desktop)</li>
<li>Safari (Mobile)</li>
<li>Android Browser</li>
<li>Opera</li>
<li>Opera Mobile</li>
</ul>
<p>&nbsp;</p>
<p><strong>Ponovno reguliranje i kontroliranje kolačića</strong></p>
<p>Svakim posjetom na&scaron;e web stranice možete se odlučiti na ponovno/reguliranje i kontroliranje kolačića te ih u svakom trenutku možete obrisati i/ili izmijeniti.</p>
<p>&nbsp;</p>
<p><strong>PRAVILA O PRIVATNOSTI</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>O pravilima privatnosti</strong></p>
<p>Ova pravila o privatnosti ("pravila") obja&scaron;njavaju kako OPG Miodrag De&scaron;a prikuplja, koristi i upravlja va&scaron;im osobnim podacima koji se nalaze na web stranici i koji su OPG Miodrag De&scaron;a dostupni kori&scaron;tenjem web stranice www.desa.hr</p>
<p>OPG Miodrag De&scaron;a je posvećen za&scaron;titi i po&scaron;tivanju va&scaron;e privatnosti. Pročitajte ovo pravila pažljivo kako biste razumjeli za&scaron;to i kako prikupljamo va&scaron;e osobne podatke te kako će se oni koristiti. U pogledu osobnih podataka koje prikupljamo, OPG Miodrag De&scaron;a je onaj koji određuje potrebe za koje i sredstva kojima se osobni podaci obrađuju.</p>
<p>&nbsp;</p>
<p>OPG Miodrag De&scaron;a kao pružatelj usluga web stranice www.desa.hr zauzima se za za&scaron;titu privatnosti i osobnih podataka.</p>
<p>&nbsp;</p>
<p>Ako nas želite kontaktirati u vezi ovih pravila ili u vezi va&scaron;ih osobnih podataka, molimo vas da koristite sljedeće kontakt podatke:</p>
<p>Naziv tvrtke: OPG Miodrag De&scaron;a</p>
<p>Adresa: Prilaz Josipa Torbarine 5, 23 000 Zadar, Hrvatska</p>
<p>E-po&scaron;te : miodrag.desa@gmail.com</p>
<p><strong>Kako i kada prikupljamo va&scaron;e osobne podatke? </strong></p>
<p>OPG Miodrag De&scaron;a osobne podatke prikuplja radi jedinstvene identifikacija korisnika, analize poslovanja, marketinga, provedbe sustava nagrađivanja, dostave proizvoda, izrade dokumentacije vezane za newsletter te drugih vrsta tehničke podr&scaron;ke.</p>
<p>&nbsp;</p>
<p>Vi&scaron;e informacija o situacijama i okolnostima za prikupljanje va&scaron;ih osobnih podataka možete pronaći ispod, u odjeljku Za &scaron;to ćemo koristiti va&scaron;e osobne podatke?</p>
<p>&nbsp;</p>
<p>Sjetite se da su to informacije o potencijalnim načinima kori&scaron;tenja osobnih podataka, s kojima se možete susresti kada posjetite na&scaron;u web stranicu ili koristite na&scaron;e usluge. U svakom od navedenih slučajeva, dobit ćete i jasne dodatne informacije o svrsi obrade podataka, a u nekim slučajevima osim toga trebat ćemo i va&scaron;e izričito dopu&scaron;tenje za obradu.</p>
<p>&nbsp;</p>
<p>Mi ne koristimo Va&scaron;e osobne podatke u marketin&scaron;ke svrhe osim ako nam ne kažete da se s time slažete. Ako želite da vam po&scaron;aljemo detalje o na&scaron;im uslugama, ponudama i promocijama, molimo da se pretplatite na to kada se pretplaćujete na na&scaron; besplatni newsletter. Za vi&scaron;e informacija posjetite odjeljak Marketing.</p>
<p>&nbsp;</p>
<p>Prema va&scaron;im osobnim podacima odnosimo se kao prema povjerljivim podacima, a pravilno ih &scaron;titi OPG Miodrag De&scaron;a<br /> &nbsp;</p>
<p><strong>Koje podatke prikupljamo direktno od vas?</strong></p>
<p>Kada se korisnik uključi u određene aktivnosti na web stranici www.desa.hr kao &scaron;to su pretplata na na&scaron; besplatni newsletter, objavljivanje komentara, objavljivanje sadržaja, sudjelovanje u natjecanjima ili nagradnim igrama, slanje povratnih informacija, traženje informacija o uslugama, javljanje na oglas za posao, OPG Miodrag De&scaron;a može od korisnika zatražiti davanje određenih dodatnih osobnih podataka. U tom slučaju, prije davanja dodatnih osobnih podataka korisnik je obvezan proučiti ova Pravila privatnosti i pristati na njihovu primjenu u odnosu na dodatne podatke.</p>
<p>&nbsp;</p>
<p>Ovisno o vrstama aktivnosti, neki su od traženih podataka određeni kao obvezni, a neki kao dobrovoljni. Ako korisnik ne želi pružiti obvezne podatke za aktivnost koja ih zahtijeva, istome neće biti dopu&scaron;teno uključivanje u tu aktivnost.</p>
<p>&nbsp;</p>
<p>Uvid u osobne podatke korisnika mogu imati, u izvr&scaron;avanju svoga djelokruga posla slijedeće osobe: pravne osobe koje sudjeluju u izvr&scaron;avanju pravnih odnosa sa korisnicima kao &scaron;to su dostavne službe, knjigovodstveni servisi, informatička podr&scaron;ka, marketin&scaron;ka podr&scaron;ka, dru&scaron;tva koja su povezana sa OPG Miodrag De&scaron;a, javna tijela koja zahtijevaju dostavu osobnih podataka u skladu sa propisima koji obvezuju OPG Miodrag De&scaron;a, a o čemu OPG Miodrag De&scaron;a vodi propisane evidencije aktivnosti obrade.</p>
<p>&nbsp;</p>
<p><strong>Kakva prava na privatnost imate? </strong></p>
<p>OPG Miodrag De&scaron;a po&scaron;tuje da svaki korisnik treba imati mogućnost osigurati točnost, potpunost i ažuriranost svojih osobnih podataka. Ako korisnik smatra da su njegovi osobni podaci nepotpuni, netočni ili nisu ažurirani, može kontaktirati OPG Miodrag De&scaron;a slanjem e-poruke na miodrag.desa@gmail.com.</p>
<p>Imajte na umu da u bilo kojem trenutku imate pravo od OPG Miodrag De&scaron;a zatražiti sljedeće:</p>
<p>&nbsp;</p>
<p>- da vam omogući pristup va&scaron;im osobnim podacima OPG Miodrag De&scaron;a možete pitati koje va&scaron;e osobne podatke koristi, a možete i tražiti pristup tim osobnim podacima. Imate pravo znati svrhu obrade, koje kategorije va&scaron;ih osobnih podataka čuvamo, tijela ili kategorije tijela s kojima va&scaron;e osobne podatke dijelimo, razdoblje zadržavanja podataka, kao i izvor podataka u slučaju kada se podaci neizravno prikupljaju.</p>
<p>&nbsp;</p>
<p>-&nbsp; da vam osigura kopiju osobnih podatke koje čuvamo:</p>
<p>&nbsp;</p>
<p>Možete nas kontaktirati ako želite kopiju nekih ili svih osobnih podataka koje čuvamo o vama.</p>
<p>&nbsp;</p>
<p>- zatražiti ispravak pogre&scaron;nih podataka:</p>
<p>&nbsp;</p>
<p>Želimo da su va&scaron;e osobne informacije točne i ažurne. Možete nas tražiti da ispravimo ili uklonimo informacije koje mislite da su netočne ili zastarjele.</p>
<p>&nbsp;</p>
<p>- zatražiti brisanje osobnih podataka:</p>
<p>&nbsp;</p>
<p>Možete tražiti da zaustavi obradu ili čak tražiti brisanje osobnih podataka o vama koje čuvamo. Ako va&scaron;e osobne podatke trebamo za obavljanje neke ugovorne obveze prema vama, OPG Miodrag De&scaron;a bi mogao prestati biti sposoban za izvođenje takvih ugovornih obveza. Također, ako su va&scaron;i osobni podatci potrebni kako bi mogli ostvariti određene zakonske obveze (npr. porezne obveze), va&scaron; zahtjev se možda neće moći ostvariti.</p>
<p>&nbsp;</p>
<p>- Ograničavanje pristupa va&scaron;im podacima (nama i/ili trećim osobama) u određenim procesima ili u potpunosti:</p>
<p>&nbsp;</p>
<p>Ako želite pobijati točnost podataka, ili vi&scaron;e ne trebamo osobne podatke za svrhu obrade, ali su oni vama potrebni za uspostavljanje, izvr&scaron;enje ili obradu pravnih zahtjeva ili ste se protivili obradi po osnovi koju smatramo legitimnom, imate pravo tražiti ograničavanje obrade osobnih podataka.</p>
<p>&nbsp;</p>
<p>- podnijeti prigovor na način kako koristimo va&scaron;e podatke:</p>
<p>&nbsp;</p>
<p>Sjetite se da imate pravo na prigovor obradi osobnih podataka koja se temelji na pravnoj osnovi koju OPG Miodrag De&scaron;a smatra legitimnom.</p>
<p>&nbsp;</p>
<p>-&nbsp; zahtijevati prijenos podataka drugom vr&scaron;itelju obrade (prenosivost prava):</p>
<p>&nbsp;</p>
<p>Ako se obrada temelji na va&scaron;em pristanku ili se vr&scaron;i automatskim sredstvima, imate pravo tražiti od OPG Miodrag De&scaron;a prijenos podataka drugom vr&scaron;itelju obrade.</p>
<p>&nbsp;</p>
<p>Kako biste ostvarili bilo koje od gore navedenih prava molimo koristite OPG Miodrag De&scaron;a kontakt podataka iz odjeljka o privatnosti.</p>
<p>&nbsp;</p>
<p>Ako niste zadovoljni kako smo prikupili ili upotrijebili va&scaron;e osobne podatke, možete podnijeti formalnu pritužbu Agenciji za za&scaron;titu osobnih podataka.</p>
<p>&nbsp;</p>
<p><strong>Gdje se čuvaju va&scaron;i osobni podaci?</strong></p>
<p>Osobne podatke koje prikupimo o vama čuvamo u sigurnom okruženju. Va&scaron;i osobni podaci su za&scaron;tićeni od neovla&scaron;tenog pristupa, otkrivanja, uporabe, izmjena ili uni&scaron;tenja od strane bilo koje organizacije ili pojedinca.</p>
<p>&nbsp;</p>
<p>Neki podaci jo&scaron; uvijek se pohranjuju u papirnatom obliku, ali imamo tendenciju digitalizirati sve osobne podatke koje obrađujemo. Obrađeni podaci pohranjuju se u na&scaron;im prostorijama i IT sustavima, ali ponekad podatke pohranjujemo na servere na&scaron;ih pouzdanih davatelja usluga (trusted service provider).</p>
<p>&nbsp;</p>
<p>OPG Miodrag De&scaron;a će osigurati da se osobni podaci korisnika drže na sigurnom mjestu (koje uključuje razumnu administrativnu, tehničku i fizičku za&scaron;titu kako bi se onemogućilo neovla&scaron;teno kori&scaron;tenje, pristup, razotkrivanje, kopiranje ili izmjena osobnih podataka), kojem mogu pristupiti samo ovla&scaron;tene osobe OPG Miodrag De&scaron;a.</p>
<p>Prikupljeni podaci za svrhe navedene u ovim Pravilima bit će pohranjeni samo onoliko dugo koliko je to potrebno za ispunjenje navedenih svrha. Va&scaron;i se osobni podaci neće čuvati u obliku koji omogućuje da budete identificirani duže nego &scaron;to OPG Miodrag De&scaron;a razumno smatra da je neophodno za ostvarenje svrhe za koju su prikupljeni ili obrađeni. OPG Miodrag De&scaron;a će određene osobne podatke čuvati u vremenskom periodu koji propisuje zakon odnosno propis koji obvezuje OPG Miodrag De&scaron;a na čuvanje podataka.</p>
<p>&nbsp;</p>
<p>Ako ste nam dali privolu, Va&scaron;e osobne podatke obrađivat ćemo do povlačenja privole. Ako izjavite osnovani prigovor na obradu osobnih podataka temeljem legitimnog interesa Va&scaron;e osobne podatke nećemo obrađivati u budućnosti.</p>
<p>&nbsp;</p>
<p>Ako je pokrenut sudski, upravni ili izvansudski postupak, osobni se podaci mogu pohraniti do kraja takvog postupka, uključujući i moguće razdoblje za izjavljivanje pravnih lijekova. OPG Miodrag De&scaron;a će određene osobne podatke čuvati u vremenskom periodu koji propisuje zakon odnosno propis koji obvezuje voditelja obrade na čuvanje podataka.</p>
<p>&nbsp;</p>
<p><strong>DA li OPG Miodrag De&scaron;</strong>a<strong> razmjenjuje podatke s trećim osobama?</strong></p>
<p>Za&scaron;tita privatnosti je nam važna, stoga nikada nećemo dijeliti va&scaron;e osobne podatke s trećom osobama osim za svrhe opisane u ovim Pravilima.</p>
<p>&nbsp;</p>
<p>Kao &scaron;to znate, OPG Miodrag De&scaron;a surađuje s drugim kompanijama. To znači da ponekad dijelimo va&scaron;e osobne podatke, koristeći sigurne IT sustave. Kada tako postupamo podaci se prenose na servere koji se nalaze u EU ili u zemlji koja osigurava adekvatnu razinu za&scaron;tite u skladu s EU zakonodavstvom.</p>
<p>&nbsp;</p>
<p>Uz navedeno, va&scaron;e osobne informacije možemo dati na&scaron;im pouzdanim partnerima koji održavaju na&scaron; IT sustav ili pružaju usluge u ime OPG Miodrag De&scaron;a. Na primjer, za potrebe marketinga, financija, ogla&scaron;avanje, obrade plaćanja, isporuke i druge usluge u i izvan OPG Miodrag De&scaron;a. Ti davatelji usluga su ipak dužni, prema relevantnim ugovorima, koristiti podatke koji su im povjereni samo u skladu s na&scaron;im smjernicama i isključivo u svrhu koju smo strogo odredili. Također obvezujemo ih da adekvatno za&scaron;titite va&scaron;e podatke i da ih smatraju poslovnom tajnom.</p>
<p>&nbsp;</p>
<p>U nekim slučajevima, na&scaron;i partneri koji pružaju usluge u ime ili za OPG Miodrag De&scaron;a mogu va&scaron;e podatke obraditi izvan Europske unije. Međutim, ugovori koje sklapamo s takvim subjektima obvezuju ih da va&scaron;im podacima postupaju sa posebnim sigurnosnim mjerama u skladu s propisima na snazi u državama članicama Europske unije.</p>
<p>&nbsp;</p>
<p><strong>Koliko dugo će OPG Miodrag De&scaron;a zadržati va&scaron;e osobne podatke? </strong></p>
<p>OPG Miodrag De&scaron;a neće zadržati va&scaron;e osobne podatke dulje od razdoblja za koje su podaci potrebni kako bi se ispunila svrha njihovog kori&scaron;tenja. Vi&scaron;e informacija o razdobljima čuvanja određenih vrsta podataka nalazi se u odjeljku za &scaron;to ćemo koristiti va&scaron;e podatke?</p>
<p>&nbsp;</p>
<p><strong>Za &scaron;to ćemo koristiti va&scaron;e podatke?</strong></p>
<p>Možemo koristiti va&scaron;e osobne podatke na nekoliko različitih načina, uglavnom za obavljanje na&scaron;ih ugovornih obveza prema vama, ali ponekad i za pobolj&scaron;anje va&scaron;eg iskustva kori&scaron;tenja web stranice, direktni marketing i sigurnosnih razloga.&nbsp;&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Kolačići</strong></p>
<p>Zadržat ćemo i procijeniti informacije o Va&scaron;em nedavnom posjetu na&scaron;oj web stranici i kako ste koristili različite dijelove na&scaron;e web stranice radi analitičkih ciljeva, odnosno kako bismo razumjeli na koji način korisnici koriste na&scaron;e web stranice.</p>
<p>&nbsp;</p>
<p>Kako bi održavali web stranicu i osigurali da njezine funkcionalnosti budu na očekivanoj razini, OPG Miodrag De&scaron;a koristi tehnologiju poznatu kao "kolačići". Kolačići su male datoteke koje &scaron;aljemo na va&scaron;e računalo i možemo im kasnije pristupiti. Oni mogu biti privremeni ili trajni. Zahvaljujući kolačićima možete bez pote&scaron;koća pretraživati na&scaron;e stranice te prikazivati rezultate koji su relevantni za vas. Kolačići nam pokazuju ono &scaron;to zanima vas i druge posjetitelje na&scaron;e web stranice, &scaron;to nam pomaže da ju pobolj&scaron;amo.</p>
<p>&nbsp;</p>
<p>Vi&scaron;e o kolačićima pročitajte u Pravilima o kori&scaron;tenju kolačića.</p>
<p>&nbsp;</p>
<p><strong>Druge web stranice</strong></p>
<p>Ova Pravila privatnosti odnose se samo na upotrebu i kori&scaron;tenje podataka koje OPG Miodrag De&scaron;a prikuplja od korisnika (ispitanika). Druge web stranice kojima se može pristupiti putem web stranice www.desa.hr imaju vlastite izjave o povjerljivosti i prikupljanju podataka te načine njihovog kori&scaron;tenja i objavljivanja. Ako preko web stranice w www.desa.hr korisnik posjeti jednu ili vi&scaron;e drugih web stranica, OPG Miodrag De&scaron;a preporučuje korisnicima da pregledaju izjavu o povjerljivosti podataka te stranice.</p>
<p>&nbsp;</p>
<p>OPG Miodrag De&scaron;a nije odgovoran za načine i uvjete rada trećih strana.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Marketing </strong></p>
<p>OPG Miodrag De&scaron;a bi Vam želio poslati informacije o na&scaron;im proizvodima i uslugama za koje bi mogli biti zainteresirani. Međutim, smijemo poslati takav marketin&scaron;ki materijal samo ako se odlučite za to. Imajte na umu da čak i ako ste pristali primati na&scaron;e marketin&scaron;ke poruke, možete od primanja istih odustati u svakom trenutku.</p>
<p>&nbsp;</p>
<p>Stupanja na snagu te promjene pravila o privatnosti</p>
<p>Ova Pravila stupaju na snagu 01 Kolovoza 2019.</p>
<p>OPG Miodrag De&scaron;a zadržava pravo izmjene i dopune Pravila privatnosti te će isti biti objavljeni na web stranici.</p>

      </Container>
    </Section>
  </Layout>

  )
}

export default Privacy